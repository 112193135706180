<template>
	<div class="privacy">
		<div class="view-header">
			<img class="view-header__image" src="@/assets/foo-privacy.png">
			<h1 class="title">
				Consulta el aviso de privacidad
			</h1>
			<p class="view-header__description">
				Ponemos a tu disposición nuestro aviso de privacidad.
			</p>
			<a href="./docs/aviso-privacidad-adz.pdf" title="Aviso de Privacidad" target="_blank" class="lnk">
				Aviso Privacidad para clientes y usuarios <small>(PDF, 320kb)</small>
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Privacy"
	}
</script>